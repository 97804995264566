.strategy-card-page {
  background-color: var(--primary-color);
  color: #fff;
  z-index: 200;
  width: 100%;
  font-family: Spectral;
  font-size: 1.4rem;
  position: absolute;
  top: 100%;
}

.show-page {
  animation: .4s forwards slideUp;
}

.hide-page {
  animation: .4s backwards slideDown;
}

@keyframes slideUp {
  from {
    top: 100%;
  }

  to {
    top: var(--header-lg);
  }
}

@keyframes slideDown {
  from {
    top: var(--header-lg);
  }

  to {
    top: 100%;
  }
}

.box {
  border: .1rem var(--primary-color) solid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: .6rem 1.2rem;
  display: flex;
}

.box--h, .key--h {
  background-color: var(--main-bg-color);
}

.box--dd, .key--dd {
  background-color: var(--accent-color);
}

.box--s, .key--s {
  background-color: var(--stand--color);
}

.box--sp, .key--sp {
  background-color: var(--split--color);
}

.box--dealer {
  border: none;
  justify-self: center;
  font-weight: 800;
}

.card-container--strategy {
  grid-template-columns: 3.2rem 1fr;
  margin: 3.6rem 1.2rem 3.6rem 6.4rem;
  display: grid;
}

.card-title {
  padding: 1.2rem;
}

.hand {
  flex-direction: column;
  justify-content: center;
  gap: 1.4rem;
  font-size: 2rem;
  font-weight: 800;
  display: flex;
}

.hand-letter {
  text-transform: uppercase;
}

.key-container {
  justify-content: center;
  gap: 3rem;
  margin: 1.2rem 1.2rem 2.4rem;
  display: flex;
}

.key {
  margin-right: 1.2rem;
  padding: .6rem 2rem;
}

.row {
  grid-template-columns: .4fr repeat(10, 1fr);
  justify-items: center;
  display: grid;
}

.row .box--user {
  border: none;
  justify-content: end;
  align-items: center;
  width: 100%;
  margin-right: 1.2rem;
  font-weight: 800;
  display: flex;
}

.show-card {
  justify-content: center;
  align-items: center;
  gap: 1.4rem;
  margin-left: 6.4rem;
  font-size: 2rem;
  display: flex;
}

@media (width <= 66em) {
  .card-container--strategy {
    margin-left: 4.8rem;
  }

  .show-card {
    margin-left: 11.2rem;
  }

  .card-container--strategy {
    grid-template-columns: 6.4rem 1fr;
  }

  html {
    font-size: 60%;
  }

  .hand, .show-card {
    gap: 1.2rem;
  }

  .row {
    grid-template-columns: .6fr repeat(10, 1fr);
  }
}

@media (width <= 56em) {
  .card-container--strategy {
    margin-top: 1.2rem;
  }
}

@media (width <= 44em) {
  .row {
    grid-template-columns: .8fr repeat(10, 1fr);
  }

  .hand, .show-card {
    gap: .6rem;
  }
}

@media (width <= 35.6em) {
  .card-container--strategy {
    grid-template-columns: 2.4rem 1fr;
    margin-left: 1.2rem;
    font-size: 1.2rem;
  }

  .key-container {
    grid-template-columns: 1fr 1fr;
    grid-row: 1fr 1fr;
    column-gap: 0;
    margin-left: 8rem;
    display: grid;
  }

  .key-box--h, .key-box--s {
    grid-column: 1;
  }

  .key-box--s {
    grid-row: 2;
  }

  .key-box--dd, .key-box--sp {
    grid-column: 2;
  }

  .hand {
    font-size: 1.2rem;
  }
}

@media (width <= 30em) {
  .card-container--strategy {
    grid-template-columns: 1.4rem 1fr;
    font-size: 1rem;
  }

  .hand {
    margin-left: 0;
    font-size: 1.2rem;
  }

  .show-card {
    margin-left: 3.6rem;
    font-size: 1.2rem;
  }

  .box {
    padding: 0;
  }

  .key-container {
    font-size: 1rem;
  }

  @keyframes slideUp {
    from {
      top: 100%;
    }

    to {
      top: var(--header-sm);
    }
  }

  @keyframes slideDown {
    from {
      top: var(--header-sm);
    }

    to {
      top: 100%;
    }
  }
}

@media (width <= 25em) {
  .card-container--strategy, .box--sp {
    font-size: 1rem;
  }
}

/*# sourceMappingURL=index.e38303e1.css.map */
