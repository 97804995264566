.strategy-card-page {
  background-color: var(--primary-color);
  color: #fff;
  font-family: 'Spectral';
  font-size: 1.4rem;
  position: absolute;
  width: 100%;
  z-index: 200;
  top: 100%;
}

.show-page {
  animation: slideUp 0.4s forwards;
}

.hide-page {
  animation: slideDown 0.4s backwards;
}

@keyframes slideUp {
  from {
    top: 100%; /* Start from off the bottom of the screen */
  }
  to {
    top: var(--header-lg); /* End 8px from the top */
  }
}

@keyframes slideDown {
  from {
    top: var(--header-lg);
  }
  to {
    top: 100%;
  }
}

.box {
  border: 0.1rem var(--primary-color) solid;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 1.2rem;
}

.box--h,
.key--h {
  background-color: var(--main-bg-color);
}

.box--dd,
.key--dd {
  background-color: var(--accent-color);
}

.box--s,
.key--s {
  background-color: var(--stand--color);
}

.box--sp,
.key--sp {
  background-color: var(--split--color);
}

.box--dealer {
  border: none;
  justify-self: center;
  font-weight: 800;
}

.card-container--strategy {
  margin: 3.6rem 1.2rem 3.6rem 6.4rem;
  display: grid;
  grid-template-columns: 3.2rem 1fr;
}

.card-title {
  padding: 1.2rem;
}

.hand {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 2rem;
  font-weight: 800;
  gap: 1.4rem;
}

.hand-letter {
  text-transform: uppercase;
}

.key-container {
  margin: 1.2rem;
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 2.4rem;
}

.key {
  padding: 0.6rem 2rem;
  margin-right: 1.2rem;
}

.row {
  display: grid;
  grid-template-columns: 0.4fr repeat(10, 1fr);
  justify-items: center;
}

.row .box--user {
  border: none;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 1.2rem;
  font-weight: 800;
  width: 100%;
}

.show-card {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  gap: 1.4rem;
  margin-left: 6.4rem;
}

/* 1050px */
@media (max-width: 66em) {
  .card-container--strategy {
    margin-left: 4.8rem;
  }

  .show-card {
    margin-left: 11.2rem;
  }

  .card-container--strategy {
    grid-template-columns: 6.4rem 1fr;
  }
  html {
    font-size: 60%;
  }

  .hand,
  .show-card {
    gap: 1.2rem;
  }

  .row {
    grid-template-columns: 0.6fr repeat(10, 1fr);
  }
}

/* 900px */

@media (max-width: 56em) {
  .card-container--strategy {
    margin-top: 1.2rem;
  }
}

/* 700px */

@media (max-width: 44em) {
  .row {
    grid-template-columns: 0.8fr repeat(10, 1fr);
  }

  .hand,
  .show-card {
    gap: 0.6rem;
  }
}

/* 570px */

@media (max-width: 35.6em) {
  .card-container--strategy {
    margin-left: 1.2rem;
    grid-template-columns: 2.4rem 1fr;
    font-size: 1.2rem;
  }

  .key-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row: 1fr 1fr;
    column-gap: 0;
    margin-left: 8rem;
  }

  .key-box--h,
  .key-box--s {
    grid-column: 1;
  }

  .key-box--s {
    grid-row: 2;
  }

  .key-box--dd,
  .key-box--sp {
    grid-column: 2;
  }

  .hand {
    font-size: 1.2rem;
  }
}

/* 470px */

@media (max-width: 30em) {
  .card-container--strategy {
    grid-template-columns: 1.4rem 1fr;
    font-size: 1rem;
  }

  .hand {
    font-size: 1.2rem;
    margin-left: 0;
  }

  .show-card {
    font-size: 1.2rem;
    margin-left: 3.6rem;
  }

  .box {
    padding: 0;
  }

  .key-container {
    font-size: 1rem;
  }

  @keyframes slideUp {
    from {
      top: 100%; /* Start from off the bottom of the screen */
    }
    to {
      top: var(--header-sm); /* End 8px from the top */
    }
  }

  @keyframes slideDown {
    from {
      top: var(--header-sm);
    }
    to {
      top: 100%;
    }
  }
}

/* 
400px */
@media (max-width: 25em) {
  .card-container--strategy {
    font-size: 1rem;
  }

  .box--sp {
    font-size: 1rem;
  }
}
